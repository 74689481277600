.headerWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 2%;
  padding-bottom: 2%;
  align-items: center;
  color: #fff;
  width: 100%;
  height: 100%;
}

.logoWrapper {
  text-align: center;
  width: 100%;
}

.logoWrapper img {
  width: 35%;
}

.logoWrapper img:hover {
  cursor: pointer;
}

.navWrapper {
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
}

.loginHeaderWrapper {
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
}

.navWrapper span {
  margin: 20px;
  transition: 0.4s;
}

.navWrapper span:hover {
  color: #c7c7c7;
  cursor: pointer;
}

.loginHeaderWrapper span {
  margin: 20px;
  transition: 0.4s;
}

.loginHeaderWrapper span:hover {
  color: #c7c7c7;
  cursor: pointer;
}

.contentHeader {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.loginHeaderWrapper img {
  width: 10%;
}

.loginHeaderWrapper img:hover {
  cursor: pointer;
}

.languageMenu {
  position: absolute;
  right: 11.5%;
  z-index: 10;
}

.languageMenu span {
  margin-left: 4%;
}

.languageMenu .koreanWrapper {
  margin-top: 10%;
}

.languageMenu div:nth-child(2) {
  margin-top: 7%;
}

.languageMenu div:hover {
  cursor: pointer;
}

.mobileMenuWrapper img {
    display: none;
}

@media screen and (max-width: 1366px) {
  .navWrapper {
    display: none;
  }
  .loginHeaderWrapper span {
    display: block;
  }
  .loginHeaderWrapper img {
    display: inline-block;
    margin-left: 70%;
    width: 7%;
  }
  .logoWrapper img {
    margin-right: 40%;
    width: 40%;
  }
  .headerWrapper {
    position: fixed;
    top: -45%;
    z-index: 5;
  }
  .mobileMenuWrapper img {
    width: 10%;
  }
  .mobileMenu {
    width: 100%;
    background-color: #f1f1f1;
    z-index: 5;
    transition: 2s; /* position에 대한 트랜지션 효과 추가 */
  }
  .mobileMenu.open {
    height: 100vh;
    position: fixed;
    transition-delay: 2s; /* height에 대한 트랜지션 효과가 먼저 완료된 후 0.5초 뒤에 position에 대한 트랜지션 효과가 시작됨 */
  }
  .mobileLogoWrapper {
    margin-left: 4%;
  }
  .mobileLogoWrapper img {
    width: 45%;
    float: left;
  }
  .mobileOpenWrapper {
    text-align: center;
  }

  .mobileXButtonWrapper {
    margin-right: 7%;
  }

  .mobileXButtonWrapper img {
    width: 100px;
  }
  .mobileMenuLogoWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 20px 0;
  }
  .mobileMenu span {
    display: block;
    margin-left: 4%;
    font-size: 1.2rem;
    border-bottom: 1px solid #c7c7c7;
    padding: 20px 0;
    width: 92%;
  }
  .lanWrapper {
    display: none;
  }
  .mobileLanguageWrapper {
    text-align: left;
  }
  .mobileLanguageWrapper  {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c7c7c7;
    width: 92%;
    margin: auto;
  }
  .mobileLanguageWrapper p {
    margin-left: 10%;
  }
  .mobileLanguageWrapper img {
    width: 15%;
    height: 100%;
    margin-left: 10%;
  }
  body {
    overflow-x: hidden;
}
}

@media screen and (max-width: 767px) {
  .logoWrapper img {
    width: 70%;
    margin-left: 10%;
  }
  .mobileMenuWrapper img {
    width: 15%;
  }
}
