.portfolio2Title {
  color: #fff;
  font-weight: 700;
  font-size: 3rem;
}

.portfolio2TitleWrapper {
  border-bottom: 2px solid #fff;
  width: 15%;
  margin-left: 1.5%;
  margin-bottom: 3%;
}

.portfolio2TitleWrapper p {
    margin-bottom: 5%;
}

.detailWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4개의 컬럼을 1fr로 반복 */
  grid-gap: 20px; /* 그리드 아이템 간의 간격 설정 */
}
.portfolioItem {
  text-align: center;
}

.portfolioItem img {
    width: 400px;
    height: 400px;
}

.portfolioItem p {
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
}

@media only screen and (max-width: 1366px) {
    .detailWrapper {
      grid-template-columns: repeat(2, 1fr); /* 모바일 환경에서는 한 줄에 2개의 아이템 배치 */
    }

    .portfolio2Title {
        font-size: 2.5rem;
        margin-top: 50%;
    }
    .portfolio2TitleWrapper {
        width: 25%;
    }
  }
  
  @media only screen and (max-width: 700px) {
    .detailWrapper {
      grid-template-columns: repeat(1, 1fr); /* 모바일 환경에서는 한 줄에 1개의 아이템 배치 */
    }
    .portfolio2Title {
        font-size: 2rem;
        margin-top: 50%;
    }
    .portfolio2TitleWrapper {
        width: 50%;
    }
  }