.mainBgWrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  transition: background-image 2s ease-in-out;
  animation: fadeInBackground 1s ease-in-out forwards;
  background-size: cover;
}

.mainTextWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  z-index: 0;
}

.mainTextWrapper p:nth-child(1) {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 0;
  animation: slideUp 1s ease-in-out;
}

.mainTextWrapper p:nth-child(2) {
  font-size: 1.2rem;
  font-family: "Pretendard";
  font-weight: 200;
  color: #dcdcdc;
  animation: slideUp 1s ease-in-out;
}

.scrollWrapper img {
  width: 20%;
}
@media screen and (min-width: 1366px) {
  .mainContainer {
    overflow-x: hidden;
  }
}

@media screen and (min-width: 1367px) {
  .mainContainer .introContainer {
    display: none;
  }
  .mainContainer .portfolioContainer {
    display: none;
  }
  .mainContainer .contactContainer {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .mainTextWrapper p:nth-child(1) {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 0;
    animation: slideUp 1s ease-in-out;
  }
  .mainTextWrapper p:nth-child(2) {
    font-size: 0.8rem;
    font-family: "Pretendard";
    font-weight: 200;
    color: #dcdcdc;
    animation: slideUp 1s ease-in-out;
  }
  .mainTextWrapper {
    width: 100%;
  }
}