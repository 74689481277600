.imageSliderOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  
  .imageSliderContainer {
    position: relative;
  }
  
  .imageSliderContainer button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    color: white;
    font-size: 50px;
    cursor: pointer;
    height: 100%;
    width: 50px; /* Set a fixed width for symmetry */
  }
  
  .imageSliderContainer button:first-child {
    left: 0;
  }
  
  .imageSliderContainer button:last-child {
    right: 0;
  }
  
  img {
    max-width: 100%;
    max-height: 85vh;
  }
  