  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRight {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes borderSlideDown {
    from {
        height: 0;
    }
    to {
        height: 100vh;
    }
}

@keyframes fadeInBackground {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes slideUpDown {
  0%, 100% {
    height: 0;
  }
  50% {
    height: 100vh;
  }
}