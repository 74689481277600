.loginContainer {
    background: url('../bgimg/BlackBg3.png');
    width: 100%;
    height: 100vh;
    animation: fadeInBackground 1s ease-in-out forwards;
}

.loginLogoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70vh;
    color: #fff;
}

.loginLogoWrapper img {
    width: 10%;
    margin-bottom: 2%;
    animation: slideDown 1s ease-in-out forwards;
}

.loginLogoWrapper img:hover {
    cursor: pointer;
}

.loginLogoWrapper p {
    margin-left: 1%;   
}

.loginInputWrapper {
    width: 22%;
}

.loginInputWrapper input {
    display: block;
    width: 100%;
    height: 4.5vh;
    background: none;
    border: 1px solid #fff;
    border-radius: 10px;
    color: #fff;
    font-size: 1.1rem;
    padding-left: 3%;
}

.loginInputWrapper input:nth-child(2) {
    margin-top: 5%;
}

.loginButton {
    width: 104%;
    height: 5vh;
    margin-top: 5%;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    transition: 0.3s;
    font-family: 'Pretendard';
    font-size: 1.1rem;
}

.loginButton:hover {
    background-color: #fff;
    color: #000;
    cursor: pointer;
}

.findWrapper {
    width: 104%;
    display: flex;
    justify-content: space-between;
    margin-top: 7%;
}

.findWrapper span {
    transition: 0.3s;
}

.findWrapper span:hover {
    color: #a1a1a1;
    cursor: pointer;
}

.findWrapper span:nth-child(1) {
    border-right: 2px solid #a1a1a1;
    padding-right: 11%;
}

.findWrapper span:nth-child(3) {
    border-left: 2px solid #a1a1a1;
    padding-left: 11%;
}