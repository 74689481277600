body {
  margin: 0;
  padding: 0;
  font-family: 'Pretendard';
  list-style: none;
  background-color: #222222;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}