.contentContainer {
    animation: fadeInBackground 1s ease-in-out forwards;
    z-index: 1;
}

.contentTitle p {
    color: #fff;
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 2%;
    animation: fadeIn 2s ease-in-out forwards;
}

.contentTitle span {
    color: #c7c7c7;
    font-size: 1.2rem;
    animation: slideUp 2s ease-in-out forwards;
}

.contentTitle img {
    width: 7%;
    animation: fadeIn 2s ease-in-out forwards;
}

.backgroundVideo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
}

.video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    filter: brightness(0.6);
}

.contentWrapper {
    width: 100%;
}

.contentTitle {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1366px) {
    .contentContainer {
        width: 100%;
        height: 100vh;
    }
}

@media screen and (max-width: 767px) {
    .contentTitle p {
        font-size: 2.7rem;
    }
    .contentTitle span {
        font-size: 1rem;
    }
    .contentTitle img  {
        display: none;
    }
}