.contactContainer {
    background-image: url('../bgimg/certiBg.jpg');
    width: 100%;
    height: 100vh;
    animation: fadeInBackground 1s ease-in-out forwards;
    overflow-y: hidden;
    white-space: pre-line;
}

.contactWrapper {
    display: flex;
    background-size: cover;
    align-items: center;
}

.contactTextWrapper {
    margin-left: 4%;
    width: 20%;
    height: 60vh;
}

.contactTextWrapper p:nth-child(1) {
    color: #dfdfdf;
    margin-top: 50%;
    font-size: 1.2rem;
    animation: slideUp 1s ease-in-out;
}

.contactTextWrapper p:nth-child(2) {
    color: #fff;
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 1%;
    animation: slideRight 2s ease-in-out;
}

.contactTextWrapper p:nth-child(3) {
    color: #c7c7c7;
    font-size: 1.1rem;
    line-height: 27px;
    animation: slideUp 1s ease-in-out;
}

.contactImgWrapper {
    width: 20%;
    display: flex;
    flex-direction: column;
}

.partnerWrapper {
    border: 1px solid #fff;
    padding: 28px;
    color: #fff;
    width: 100%;
}

.partnerWrapper p:nth-child(3) {
    color: #c7c7c7;
}

.partnerWrapper p:nth-child(4) {
    display: flex;
    align-items: center;
}

.partnerWrapper img {
    margin-right: 3%;
}

.partnerWrapper p:nth-child(5) {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.developWrapper {
    border: 1px solid #fff;
    padding: 28px;
    color: #fff;
    width: 100%;
    margin-top: 9%;
}

.developWrapper p:nth-child(3) {
    color: #c7c7c7;
}

.developWrapper p:nth-child(4) {
    display: flex;
    align-items: center;
}

.developWrapper img {
    margin-right: 3%;
}

.developWrapper p:nth-child(5) {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.contactInputWrapper {
    border: 1px solid #fff;
    width: 20%;
    height: 80vh;
    margin-left: 5%;
}

.contactFooterWrapper {
    color: #c7c7c7;
    margin-left: 3%;
    width: 15%;
    margin-right: 4%;
}

.contactFooterTop {
    border-bottom: 1px solid #fff;
}

.contactFooterLogo img {
    width: 70%;
    display: block;
    margin-top: 7%;
}

.contactInputWrapper {
    color: #fff;
    width: 30%;
}

.contactInputWrapper form {
    margin-top: 5%;
}

.contactInputWrapper label {
    font-size: 1.2rem;
    margin-left: 10%;
}

.contactInputWrapper input {
    display: block;
    margin-top: 2%;
    width: 80%;
    height: 4vh;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    margin-left: 10%;
    font-size: 1rem;
}

.contactInputWrapper textarea {
    display: block;
    width: 80%;
    height: 20vh;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    outline: none;
    resize: none;
    margin-left: 10%;
    margin-top: 2.5%;
    font-family: 'Pretendard';
    font-size: 1rem;
}

.contactInputWrapper button {
    width: 30%;
    height: 5vh;
    border: 1px solid #fff;
    border-radius: 10px 0 10px 0;
    background: none;
    color: #fff;
    transition: 0.3s;
}

.contactInputWrapper button:hover {
    color: #000;
    background-color: #fff;
    cursor: pointer;
}

.termsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1%;
    width: 80%;
    margin: auto;
}

.termsWrapper div {
    display: flex;
    align-items: center;
    width: 70%;
}

.termsWrapper input {
    margin: 0;
    width: 20px;
    height: 20px;
    display: inline-block;
}

.termsWrapper span {
    text-decoration: underline;
    margin-left: 3%;
}

.termsWrapper span:hover {
    cursor: pointer;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    background-color: #000;
    border: 1px solid #fff;
    width: 50%;
    height: 50%;
    color: #fff;
    animation: fadeIn 1s ease-in-out;
  }

  .modalTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10%;
  }

  .modalTitle p {
    font-size: 1.5rem;
  }

  .modalTitle img {
    width: 2%;
  }

  .modalTitle img:hover {
    cursor: pointer;
  }

  .modalTerms {
    border: 1px solid #fff;
    padding: 20px;
    border-radius: 8px;
    max-height: 38vh;
    overflow-y: auto;
    line-height: 25px;
  }
  
  /* 스크롤바 스타일링 */
  .modalTerms::-webkit-scrollbar {
    width: 5px;
  }
  
  .modalTerms::-webkit-scrollbar-thumb {
    background-color: #ffffff; /* 스크롤바 색상 */
    border-radius: 5px; /* 스크롤바 모서리 둥글게 */
  }
  
  .modalTerms::-webkit-scrollbar-track {
    background: none; /* 스크롤바 트랙 배경색 */
  }

  @media screen and (max-width: 1366px) {
    .contactContainer {
        overflow-x: hidden;
        height: 100%;
    }
    .partnerWrapper {
        display: none;
    }
    .contactWrapper {
        display: block;
    }
    .contactTextWrapper {
        height: 15vh;
        width: 30%;
    }
    .contactTextWrapper p:nth-child(3) {
        display: none;
    }
    .contactInputWrapper {
        width: 70%;
        height: 95vh;
        margin-left: 4%;
    }
    .contactFooterTop {
        display: inline-block;
        width: 100vw;
    }
    .contactFooterTop p {
        width: 100%;
    }
    .contactContainer .headerContainer {
        display: none;
    }
    .contactFooterWrapper {
        margin-left: 4%;
    }
  }

  @media screen and (max-width: 767px) {
    .contactTextWrapper {
        width: 100%;
    }
    .contactTextWrapper p:nth-child(1) {
        font-size: 1rem;
        margin-top: 10%;
    }
    .contactTextWrapper p:nth-child(2) {
        font-size: 1.8rem;
    }
    .contactInputWrapper {
        width: 90%;
        height: 100%;
    }
    .termsWrapper {
        display: block;
        width: 100%;
    }
    .termsWrapper button {
        width: 80%;
        margin-left: 10%;
        margin-top: 10%;
        margin-bottom: 10%;
    }
    .termsFlexWrapper {
        width: 100%;
        margin-left: 10%;
    }
    .contactFooterWrapper{
        width: 100%;
        height: 40vh;
    }
    .contactFooterWrapper img{
        width: 50%;
    }
    .contactFooterLogo {
        padding-bottom: 10%;
        width: 90%;
        border-top: 1px solid #fff;
    }
    .contactFooterLogo img {
        margin: auto;
        margin-top: 10%;
    }
    .contactFooterTop {
        border-bottom: none;
    }
  }