.portfolioContainer {
  background: url("../bgimg/BlackBg3.png");
  width: 100%;
  height: 100vh;
  animation: fadeInBackground 1s ease-in-out forwards;
}

.portfolioTextWrapper {
  border-bottom: 1px solid #fff;
  width: 41.5%;
  margin-left: 8.5%;
  margin-top: 4%;
}

.portfolioTextWrapper h1 {
  color: #fff;
  font-size: 3rem;
  margin-bottom: 1.5%;
}

.portfolioContentWrapper {
  width: 100%;
  height: 90vh;
  display: flex;
}

.portfolioMiddleWrapper {
  width: 33%;
  height: 100%;
}

.nursenseWrapper {
  position: relative;
  width: 34%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.nursenseWrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../bgimg/nursense.jpg");
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease-in-out;
  transform-origin: center center;
  transform: scale(1);
}

.nursenseWrapper:hover::before {
  transform: scale(1.2);
  filter: brightness(0.5);
}

.siminWrapper {
  position: relative;
  width: 100%;
  height: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  white-space: pre-line
}

.siminWrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../bgimg/safty.jpg");
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease-in-out;
  transform-origin: center center;
  transform: scale(1);
}

.siminWrapper:hover::before {
  transform: scale(1.2);
  filter: brightness(0.5);
}

.jenanWrapper {
  position: relative;
  width: 100%;
  height: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.jenanWrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../bgimg/jenan.jpg");
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease-in-out;
  transform-origin: center center;
  transform: scale(1);
}

.jenanWrapper:hover::before {
  transform: scale(1.2);
  filter: brightness(0.5);
}

.fireWrapper {
  position: relative;
  width: 100%;
  height: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fireWrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../bgimg/fire.jpg");
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease-in-out;
  transform-origin: center center;
  transform: scale(1);
}

.fireWrapper:hover::before {
  transform: scale(1.2);
  filter: brightness(0.5);
}

.eduWrapper {
  position: relative;
  width: 100%;
  height: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.eduWrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../bgimg/edu.jpg");
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease-in-out;
  transform-origin: center center;
  transform: scale(1);
}

.eduWrapper:hover::before {
  transform: scale(1.2);
  filter: brightness(0.5);
}

.portfolioContentWrapper img {
  width: 50px;
  height: 50px;
  opacity: 0;
  z-index: 1;
  margin-top: 5%;
  margin-bottom: 5%;
}

.nursenseWrapper:hover img,
.siminWrapper:hover img,
.fireWrapper:hover img,
.jenanWrapper:hover img,
.eduWrapper:hover img {
  opacity: 1;
  cursor: pointer;
}

.portfolioContentWrapper h1 {
  z-index: 1;
  opacity: 0;
  color: #fff;
  margin: 0;
  transition: 0.5s;
}

.nursenseWrapper:hover h1,
.siminWrapper:hover h1,
.fireWrapper:hover h1,
.jenanWrapper:hover h1,
.eduWrapper:hover h1 {
  opacity: 1;
}

.portfolioContentWrapper span {
  z-index: 1;
  opacity: 0;
  color: #f7f7f7;
  transition: 0.5s;
}

.nursenseWrapper:hover span,
.siminWrapper:hover span,
.fireWrapper:hover span,
.jenanWrapper:hover span,
.eduWrapper:hover span {
  opacity: 1;
}

.portfolioMoreWrapper button {
  position: absolute;
  bottom: 0;
  left: 46%;
  width: 10%;
  height: 5vh;
  background: none;
  border: 2px solid#fff;
  color: #fff;
  border-radius: 50px;
  font-size: 1.2rem;
  transition: 0.3s;
}

.portfolioMoreWrapper button:hover {
  background-color: #fff;
  color: #000;
  cursor: pointer;
}

@media screen and (max-width: 1366px) {
  .portfolioContentWrapper {
    display: block;
  }
  .portfolioContainer .headerContainer {
    display: none;
  }
  .portfolioContainer {
    overflow-y: auto;
  }
  .nursenseWrapper,
  .siminWrapper,
  .jenanWrapper,
  .fireWrapper,
  .eduWrapper {
    width: 100%;
    height: 40vh;
  }
  .portfolioMiddleWrapper {
    width: 100%;
  }
  .nursenseWrapper span,
  .siminWrapper span,
  .fireWrapper span,
  .jenanWrapper span,
  .eduWrapper span {
    opacity: 1;
  }
  .nursenseWrapper::before,
  .siminWrapper::before,
  .fireWrapper::before,
  .jenanWrapper::before,
  .eduWrapper::before {
    transform: scale(1.2);
    filter: brightness(0.5);
  }
  .nursenseWrapper img,
  .siminWrapper img,
  .fireWrapper img,
  .jenanWrapper img,
  .eduWrapper img {
    opacity: 1;
    cursor: pointer;
  }
  .nursenseWrapper h1,
  .siminWrapper h1,
  .fireWrapper h1,
  .jenanWrapper h1,
  .eduWrapper h1 {
    opacity: 1;
  }
  .portfolioMoreWrapper button {
    display: none;
  }
}
